import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { FXManageCurrenciesTypes } from './types'

import { callApiGet, callApiPost } from '../../utils/api'
import { path } from '../../helpers/path'
import { logout } from '../auth'
import {
  createFxFailure,
  createFxUpdateSuccess,
  createFxSuccess,
  getAllCurrenciesFailure,
  getAllCurrenciesSuccess,
  getCurrencyCodesSuccess
} from './actions'
import { FxCurrencies } from '../../interfaces/FxManage'
import { Register } from '../../interfaces'

function* getAllFXCurrencies(): any {
  try {
    const res = yield call(callApiGet, 'payments/getfxcurrencies')

    yield put(getAllCurrenciesSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else {
        yield put(getAllCurrenciesFailure(err.response.data))
      }
    } else {
      yield put(getAllCurrenciesFailure('An unknown error occurred'))
    }
  }
}

function* updateFxCurrency({ payload }: { type: string; payload: FxCurrencies }): any {
  try {
    const res = yield call(callApiPost, 'payments/updatefxcurrencies', payload)
    yield put(createFxUpdateSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else {
        if (err.response.data.status) {
          yield put(createFxFailure(err.response.data))
        } else {
          yield put(createFxFailure('An error occured'))
        }
      }
    } else {
      throw err
    }
  }
}


function* getCurrencyCodes(): any {
  try {
    const res = yield call(callApiGet, "payments/currencycodes");
    yield put(getCurrencyCodesSuccess(res.data));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(getAllCurrenciesFailure(err.response.data));
      }
    } else {
      yield put(getAllCurrenciesFailure("An unknown error occurred"));
    }
  }
}

function* createFx({ payload }: { type: string; payload: Register }): any {
  try {

    const res = yield call(callApiPost, "payments/addfxentry", payload);
    yield put(createFxSuccess(res.data));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        if (err.response.data.status) {
          yield put(createFxFailure(err.response.data));
        } else {
          yield put(createFxFailure("An error occured"));
        }
      }
    } else {
      throw err;
    }
  }
}

function* watchGetAllFXCurrencies() {
  yield takeEvery(FXManageCurrenciesTypes.GET_FX_CURRENCIES_REQUEST, getAllFXCurrencies)
}

function* watchUpdateFxCurrency() {
  yield takeEvery(FXManageCurrenciesTypes.POST_FX_UPDATE_FX_CURRENCY, updateFxCurrency)
}

function* watchGetCurrencyCodes() {
  yield takeEvery(FXManageCurrenciesTypes.CURRENCYCODES, getCurrencyCodes)
}

function* watchCreateFx() {
  yield takeEvery(FXManageCurrenciesTypes.CREATE_CURRENCY_REQUEST, createFx)
}

function* fxCurrenciesManagementSaga(): Generator {
  yield all([
    fork(watchGetAllFXCurrencies),
    fork(watchUpdateFxCurrency),
    fork(watchGetCurrencyCodes),
    fork(watchCreateFx)
  ])
}

export { fxCurrenciesManagementSaga }
