export enum B2CTransactionTypes {
  B2C_PAYMENT_REQUEST = '@@b2ctransactions/B2C_PAYMENT_REQUEST',
  B2C_PAYMENT_SUCCESS = '@@b2ctransactions/B2C_PAYMENT_SUCCESS',
  B2C_PAYMENT_FAILURE = '@@b2ctransactions/B2C_PAYMENT_FAILURE',
  CLEAR_STATES = '@@b2ctransactions/CLEAR_STATES'
}

export type B2CTransactionState = {
  readonly isSubmitting: boolean
  readonly loading: boolean
  readonly error: any
  readonly b2cpay: null
  readonly success: boolean
  readonly failure: boolean
}
