import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { FileUploadTypes } from './types'
import { callApiPost } from '../../utils/api'
import { PaymentPage } from '../../interfaces'
import { uploadFailure, uploadSuccess } from './actions'
import { path } from '../../helpers/path'
import { logout } from '../auth'

function* uploadFile({ payload }: { type: string; payload: PaymentPage }): any {
  try {
    const res = yield call(callApiPost, 'payments/importfile', payload)
    yield put(uploadSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else {
        yield put(uploadFailure(err.response.data))
      }
    } else {
      yield put(uploadFailure('An error occured when making request to server'))
    }
  }
}

function* watchUploadFile() {
  yield takeEvery(FileUploadTypes.UPLOAD_FILE, uploadFile)
}

function* bulkPaymentSaga(): Generator {
  yield all([fork(watchUploadFile)])
}

export { bulkPaymentSaga }
