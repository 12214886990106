import { Reducer } from 'redux'
import { B2CTransactionTypes, B2CTransactionState } from './types'

export const initialState: B2CTransactionState = {
  error: undefined,
  failure: false,
  loading: false,
  b2cpay: null,
  success: false,
  isSubmitting: false
}

const reducer: Reducer<B2CTransactionState> = (state = initialState, action) => {
  switch (action.type) {
    case B2CTransactionTypes.B2C_PAYMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: {},
        success: false,
        failure: false
      }

    case B2CTransactionTypes.B2C_PAYMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        b2cpay: action.payload,
        success: true,
        failure: false,
        error: {}
      }

    case B2CTransactionTypes.B2C_PAYMENT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        success: false,
        failure: true
      }
    case B2CTransactionTypes.CLEAR_STATES:
      return {
        ...state,
        isSubmitting: false,
        error: undefined,
        success: false,
        failure: false
      }
    // case B2CTransactionTypes.GET_TRANSACTIONS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: undefined,
    //   };
    // case B2CTransactionTypes.GET_TRANSACTIONS_SUCCESS:
    //   return {
    //     ...state,
    //     transactions: action.payload,
    //     loading: false,
    //   };
    // case B2CTransactionTypes.GET_TRANSACTIONS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    default:
      return state
  }
}

export { reducer as B2CPaymentReducer }
