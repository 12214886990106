import React from 'react'
import { Table, Row, Col /*, Card*/ } from 'antd'
import { Page } from '../../interfaces'
import moment from 'moment-timezone'

interface PagesProps {
  pages: Page[]
  onClickRow(pageId: number): void
  onPreviewClick(processId: string): void
  loading: boolean
  translate: any
}

interface TableData {
  key: number
  paymentPageId: number
  pageName: string
  description: string
  customerName: string
  amount: string
  phoneNumber: string
  emailAddress: string
  currency: string
  processId: string
  createdWhen: string
}

const DataTable: React.FC<PagesProps> = ({
  pages,
  onClickRow,
  loading,
  translate,
}) => {

  let tzdiff = (new Date().getTimezoneOffset() / 60) * -1

  const columns: any[] = [
    {
      title: <span style={{ fontSize: '2rem', color: '#868686' }}>&bull;</span>,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      className: 'column-text',
      render: (status: string) => {
        return (
          <span style={{ fontSize: '2rem', color: '#41b883' }}>&bull;</span>
        )
      },
    },
    {
      title: `${translate('general.msisdn')}`,
      dataIndex: 'msisdn',
      key: 'msisdn',
      align: 'left',
      render: (pageName: string, page: TableData) => (
        <span
          style={{ color: '#35b9e6' }}
          onClick={() => onClickRow(page.paymentPageId)}
        >
          {pageName}
        </span>
      ),
    },
    {
      title: `${translate('general.provider')}`,
      dataIndex: 'provider',
      key: 'provider',
      align: 'left',
    },
    {
      title: `${translate('general.currency')}`,
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
    },
    {
      title: `${translate('general.amount')}`,
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
  ]

  let data: TableData[] = []
  for (let page of pages) {
    data.push({
      key: page.paymentPageId,
      paymentPageId: page.paymentPageId,
      pageName: page.pageName,
      description: page.description,
      customerName: page.customerName,
      amount:
        page.amount !== ''
          ? `${page.currency} ${parseFloat(page.amount).toFixed(2)}`
          : page.amount,
      phoneNumber: page.phoneNumber,
      emailAddress: page.emailAddress,
      currency: page.currency,
      processId: page.processId,
      createdWhen: moment(page.createdWhen)
        .add(tzdiff, 'hour')
        .format(`MMMM D, YYYY (h:mm a)`),
    })
  }

  return (
    <Row gutter={20}>
      <Col span={24}>
        <div className="table-padding">
          <Table
            dataSource={data}
            columns={columns}
            bordered
            pagination={{
              hideOnSinglePage: true,
              total: data.length,
              showTotal: (total, range) => {
                const tran = translate(`general.pagination`)
                let t = tran.replace(`%d`, `${range[0]} - ${range[1]}`)
                let s = t.replace(`%s`, total)
                return s
              },
            }}
            loading={loading}
          />
        </div>
      </Col>
    </Row>
  )
}

export default DataTable
