import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { B2CTransactionTypes } from './types'
import { callApiPost } from '../../utils/api'
import { B2CTransaction } from '../../interfaces'
import { b2cPaymentFailure, b2cPaymentSuccess } from './actions'
import { path } from '../../helpers/path'
import { logout } from '../auth'

function* b2cPaymentPage({
  payload
}: {
  type: string
  payload: B2CTransaction
}): any {
  try {
    const res = yield call(callApiPost, 'payments/makemomopay', payload)

    yield put(b2cPaymentSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else {
        yield put(b2cPaymentFailure(err.response.data))
      }
    } else {
      yield put(
        b2cPaymentFailure('An error occured when making request to server')
      )
    }
  }
}

function* watchb2cPaymentPage() {
  yield takeEvery(B2CTransactionTypes.B2C_PAYMENT_REQUEST, b2cPaymentPage)
}

function* b2cPaymentPageSaga(): Generator {
  yield all([fork(watchb2cPaymentPage)])
}

export { b2cPaymentPageSaga }
