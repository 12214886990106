import { action } from 'typesafe-actions'
import { CurrenciesTypes } from './types'
import { Currency } from '../../interfaces'
import { FxEntry } from '../../interfaces/FxEntry'

export const clearSomeBooleans = () => action(CurrenciesTypes.CLEAR_SOME_BOOLEANS)

export const getFxCurrencies = () => action(CurrenciesTypes.GET_FX_CURRENCIES)

export const getAllCurrenciesRequest = () => action(CurrenciesTypes.GET_CURRENCIES_REQUEST)

export const getFXCurrencies = () => action(CurrenciesTypes.GET_CURRENCIES_REQUEST)

export const getAllCurrenciesSuccess = (response: Currency[]) =>
  action(CurrenciesTypes.GET_CURRENCIES_SUCCESS, response)

export const getAllCurrenciesFailure = (error: any) =>
  action(CurrenciesTypes.GET_CURRENCIES_FAILURE, error)

export const createFxRequest = (payload: FxEntry) =>
  action(CurrenciesTypes.CREATE_CURRENCY_REQUEST, payload)

export const createFxSuccess = (response: any) =>
  action(CurrenciesTypes.CREATE_CURRENCY_SUCCESS, response)

export const createFxFailure = (error: any) =>
  action(CurrenciesTypes.CREATE_CURRENCY_FAILURE, error)
