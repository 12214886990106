import { Reducer } from 'redux'
import { FXManageCurrenciesState, FXManageCurrenciesTypes } from './types'
import { AuthActionTypes } from '../auth'

export const initialState: FXManageCurrenciesState = {
  isSubmitting: false,
  error: undefined,
  currenciesLoading: false,
  loading: false,
  createFxFailure: false,
  createFxSuccess: false,
  allCurrencies: [],
  currencyError: undefined,
  currencyCodes: []
}

const reducer: Reducer<FXManageCurrenciesState> = (state = initialState, action) => {
  switch (action.type) {
    case FXManageCurrenciesTypes.GET_FX_CURRENCIES_REQUEST:
      return {
        ...state,
        currenciesLoading: true,
      }

    case FXManageCurrenciesTypes.POST_FX_UPDATE_FX_CURRENCY:
      return {
        ...state,

        currenciesLoading: true,
      }

    case FXManageCurrenciesTypes.POST_FX_UPDATE_FX_CURRENCY_SUCCESS:
      return {
        ...state,
        createFxSuccess: true,
      }

    case FXManageCurrenciesTypes.GET_FX_CURRENCIES_SUCCESS:
      return {
        ...state,
        currenciesLoading: false,
        allCurrencies: action.payload,
      }
    case FXManageCurrenciesTypes.GET_FX_CURRENCIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      }

    case FXManageCurrenciesTypes.CLEAR_SOME_BOOLEANS:
      return {
        ...state,
        isSubmitting: false,
        createFxFailure: false,
        createFxSuccess: false,
        error: undefined,
        currencyError: undefined,
        currenciesLoading: false,
      }

    case AuthActionTypes.DESTROY_STATES:
      return initialState

    case FXManageCurrenciesTypes.CURRENCYCODES:
      return {
        ...state,
      }
    
    case FXManageCurrenciesTypes.CURRENCYCODES_SUCCESS:
      return {
        ...state,
        currencyCodes: action.payload,
      }
    
    case FXManageCurrenciesTypes.CREATE_CURRENCY_SUCCESS:
      return {
        ...state,
        createFxSuccess: true,
      }
    default:
      return state
  }
}

export { reducer as fxCurrencyManagerReducer }
