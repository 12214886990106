import { Currency } from '../../interfaces'
import { FxCurrencies } from '../../interfaces/FxManage'

export enum CurrenciesTypes {
  CLEAR_SOME_BOOLEANS = '@@fxCurrencies/CLEAR_SOME_BOOLEANS',
  GET_CURRENCIES_REQUEST = '@@fxCurrencies/GET_CURRENCIES_REQUEST',
  GET_CURRENCIES_SUCCESS = '@@fxCurrencies/GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAILURE = '@@fxCurrencies/GET_CURRENCIES_FAILURE',
  CREATE_CURRENCY_REQUEST = '@@fxCurrencies/CREATE_CURRENCY_REQUEST',
  CREATE_CURRENCY_SUCCESS = '@@fxCurrencies/CREATE_CURRENCY_SUCCESS',
  CREATE_CURRENCY_FAILURE = '@@fxCurrencies/CREATE_CURRENCY_FAILURE',
  GET_FX_CURRENCIES = '@@fxCurrencies/GET_FX_CURRENCIES',
}

export type CurrenciesState = {
  readonly loading: boolean
  readonly isSubmitting: boolean
  readonly error: any
  readonly currenciesLoading: boolean
  readonly createFxSuccess: boolean
  readonly createFxFailure: boolean

  readonly allCurrencies: Currency[]

  readonly currencyError: any
}

export type FxCurrenciesState = {
  readonly loading: boolean
  readonly isSubmitting: boolean
  readonly error: any
  readonly currenciesLoading: boolean
  readonly createFxSuccess: boolean
  readonly createFxFailure: boolean

  readonly allCurrencies: FxCurrencies[]
  readonly currencyCodes: Currency[]

  readonly currencyError: any
}
