import { Currency } from '../../interfaces'
import { FxCurrencies } from '../../interfaces/FxManage'

export enum FXManageCurrenciesTypes {
  CLEAR_SOME_BOOLEANS = '@@fxManage/CLEAR_SOME_BOOLEANS',
  GET_FX_CURRENCIES_REQUEST = '@@fxManage/GET_FX_CURRENCIES_REQUEST',
  POST_FX_UPDATE_FX_CURRENCY = '@@fxManage/POST_FX_UPDATE_FX_CURRENCY',
  POST_FX_UPDATE_FX_CURRENCY_SUCCESS = '@@fxManage/POST_FX_UPDATE_FX_CURRENCY_SUCCESS',
  GET_FX_CURRENCIES_SUCCESS = '@@fxManage/GET_FX_CURRENCIES_SUCCESS',
  GET_FX_CURRENCIES_FAILURE = '@@fxManage/GET_FX_CURRENCIES_FAILURE',
  CURRENCYCODES = '@@fxManage/CURRENCYCODES',
  CURRENCYCODES_SUCCESS = '@@fxManage/CURRENCYCODES_SUCCESS',
  CREATE_CURRENCY_REQUEST = '@@fxManage/CREATE_CURRENCY_REQUEST',
  CREATE_CURRENCY_SUCCESS = '@@fxManage/CREATE_CURRENCY_SUCCESS',
  CREATE_CURRENCY_FAILURE = '@@fxManage/CREATE_CURRENCY_FAILURE',
}

export type FXManageCurrenciesState = {
  readonly loading: boolean
  readonly isSubmitting: boolean
  readonly error: any
  readonly currenciesLoading: boolean
  readonly createFxSuccess: boolean
  readonly createFxFailure: boolean
  readonly allCurrencies: FxCurrencies[]
  readonly currencyCodes: Currency[]
  readonly currencyError: any
}
