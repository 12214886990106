import { Page, TransactionHistory, Fee } from '../../interfaces'

export enum FileUploadTypes {
  UPLOAD_FILE = '@@bulk-payment/UPLOAD_FILE',
  VALIDATE_FILE = '@@payment-pages/GET_PAYMENT_PAGES_SUCCESS',
  PROCESS_PAYMENT_SUCCESS = '@@payment-pages/GET_PAYMENT_PAGES_FAILURE',
  PROCESS_PAYMENT_FAILURE = '@@payment-pages/ADD_PAYMENT_PAGE_REQUEST',
  INCREASE_PAYMENT_STEP = '@@payment/INCREASE_PAYMENT_STEP',
  INCREASE_PAYMENT_STEP_CUSTOM = '@@payment/INCREASE_PAYMENT_STEP_CUSTOM',
  DECREASE_PAYMENT_STEP = '@@payment/DECREASE_PAYMENT_STEP',
  DECREASE_BULK_PAYMENT_STEP_CUSTOM = '@@payment/DECREASE_PAYMENT_STEP_CUSTOM'
}

export type BulkPaymentState = {
  readonly pages: Page[]
  readonly loading: boolean
  readonly isSubmitting: boolean
  readonly error: any
  readonly success: boolean
  readonly failure: boolean
  readonly singlePage: Page | undefined
  readonly pageTransactions: TransactionHistory[]
  readonly fee: Fee | undefined
  readonly feeError: any
  readonly feeLoading: boolean
  readonly providers: []
  readonly step: number
}
