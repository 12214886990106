import { action } from 'typesafe-actions'
import { FileUploadTypes } from './types'
import { PaymentPage, Page } from '../../interfaces'

export const uploadFile = (payload: PaymentPage) =>
  action(FileUploadTypes.UPLOAD_FILE, payload)

export const uploadFailure = (error: any) =>
  action(FileUploadTypes.PROCESS_PAYMENT_FAILURE, error)

export const uploadSuccess = (error: any) =>
  action(FileUploadTypes.PROCESS_PAYMENT_FAILURE, error)

export const validateFile = (response: Page) =>
  action(FileUploadTypes.VALIDATE_FILE, response)

export const processFile = (payload: PaymentPage) =>
  action(FileUploadTypes.UPLOAD_FILE, payload)

export const processPaymentSuccess = (response: any) =>
  action(FileUploadTypes.PROCESS_PAYMENT_SUCCESS, response)

export const processPaymentFailure = (error: any) =>
  action(FileUploadTypes.PROCESS_PAYMENT_FAILURE, error)

export const decreaseBulkPaymentStepCustom = (step: number) =>
  action(FileUploadTypes.DECREASE_BULK_PAYMENT_STEP_CUSTOM, step)
