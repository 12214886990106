import { action } from 'typesafe-actions'
import { FXManageCurrenciesTypes } from './types'
import { Currency } from '../../interfaces'
import { FxCurrencies } from '../../interfaces/FxManage'
import { FxEntry } from '../../interfaces/FxEntry'

export const clearSomeBooleans = () => action(FXManageCurrenciesTypes.CLEAR_SOME_BOOLEANS)

export const getAllFXCurrencies = () => action(FXManageCurrenciesTypes.GET_FX_CURRENCIES_REQUEST)

export const getAllCurrenciesSuccess = (response: Currency[]) =>
  action(FXManageCurrenciesTypes.GET_FX_CURRENCIES_SUCCESS, response)

export const getAllCurrenciesFailure = (error: any) =>
  action(FXManageCurrenciesTypes.GET_FX_CURRENCIES_FAILURE, error)

export const createFxRequest = (payload: FxEntry) =>
  action(FXManageCurrenciesTypes.CREATE_CURRENCY_REQUEST, payload)

export const createFxSuccess = (response: any) =>
  action(FXManageCurrenciesTypes.CREATE_CURRENCY_SUCCESS, response)

export const createFxUpdateSuccess = (response: any) =>
  action(FXManageCurrenciesTypes.POST_FX_UPDATE_FX_CURRENCY_SUCCESS, response)

export const createFxFailure = (error: any) =>
  action(FXManageCurrenciesTypes.CREATE_CURRENCY_FAILURE, error)

export const updateFxCurrency = (payload: FxCurrencies) =>
  action(FXManageCurrenciesTypes.POST_FX_UPDATE_FX_CURRENCY, payload)

export const getCurrencyCodes= () => 
  action(FXManageCurrenciesTypes.CURRENCYCODES)

export const getCurrencyCodesSuccess= (payload: Currency) => 
  action(FXManageCurrenciesTypes.CURRENCYCODES_SUCCESS, payload)
