import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Alert, Input, Form } from 'antd'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { appSelector } from '../helpers/appSelector'
import { updateFxCurrency } from '../store/fxManage'
import { FxCurrencies } from '../interfaces/FxManage'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface CurrencyData {
  SourceCurrency: string
  DestinationCurrency: string
  Selling: string
  Buying: string
  Median: string
  CreatedBy: string
  CreatedWhen: string
  CreatedWhenUTC: string
}

interface LocationState {
  currency?: CurrencyData
}

export const EditCurrency = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation<LocationState>()
  const { user } = appSelector((state) => state.auth)
  const { createFxSuccess } = appSelector((state) => state.currencies)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<CurrencyData>({
    SourceCurrency: '',
    DestinationCurrency: '',
    Selling: '',
    Buying: '',
    Median: '',
    CreatedBy: user?.username ?? '',
    CreatedWhen: '',
    CreatedWhenUTC: '',
  })

  useEffect(() => {
    // console.log('Location state:', location.state)
    if (location.state && location.state.currency) {
      // console.log('Currency data found:', location.state.currency)
      const currencyData: CurrencyData = location.state.currency
      setFormData(currencyData)
    } else {
      // console.log('Currency data not found in location state')
    }
  }, [location.state])

  const handleSubmit = async () => {
    const payload: FxCurrencies = {
      SourceCurrency: formData.SourceCurrency,
      DestinationCurrency: formData.DestinationCurrency,
      Selling: parseFloat(formData.Selling),
      Buying: parseFloat(formData.Buying),
      Median: parseFloat(formData.Median),
      CreatedBy: user?.username ?? '',
      CreatedWhen: new Date(),
      CreatedWhenUTC: new Date(),
    }

    await dispatch(updateFxCurrency(payload))

    // console.log('Updated data:', payload)
    //history.goBack()
  }

  const handleAlertClose = () => {
    history.goBack()
  }

  return (
    <Row justify="center" align="middle">
      <Col span={24} className="profile-box">
        <h3>{t('general.editCurrency')}</h3>
        <Row>
          <Col span={24}>
            <Form onFinish={handleSubmit}>
              {createFxSuccess && (
                <Alert
                  message={t('general.updateCurrencySuccess')}
                  type="success"
                  closable
                  onClose={handleAlertClose}
                  style={{ marginTop: '20px', width: '100%' }}
                />
              )}
              <span style={{ margin: '0 5px' }}></span>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.sourceCurrency')}>
                    <Input
                      value={formData.SourceCurrency}
                      onChange={(e) => setFormData({ ...formData, SourceCurrency: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.destinationCurrency')}>
                    <Input
                      value={formData.DestinationCurrency}
                      onChange={(e) =>
                        setFormData({ ...formData, DestinationCurrency: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.selling')}>
                    <Input
                      value={formData.Selling}
                      onChange={(e) => setFormData({ ...formData, Selling: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.buying')}>
                    <Input
                      value={formData.Buying}
                      onChange={(e) => setFormData({ ...formData, Buying: e.target.value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.median')}>
                    <Input
                      value={formData.Median}
                      onChange={(e) => setFormData({ ...formData, Median: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.createdBy')}>
                    <Input
                      value={formData.CreatedBy}
                      onChange={(e) => setFormData({ ...formData, CreatedBy: e.target.value })}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('general.createdAt')}>
                    <Input
                      value={formData.CreatedWhen}
                      onChange={(e) => setFormData({ ...formData, CreatedWhen: e.target.value })}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.createdAtUTC')}>
                    <Input
                      value={formData.CreatedWhenUTC}
                      onChange={(e) => setFormData({ ...formData, CreatedWhenUTC: e.target.value })}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" htmlType="submit" disabled={!!createFxSuccess}>
                {t('general.update')}
              </Button>
              <span style={{ margin: '0 5px' }}></span>
              <Button type="default" onClick={() => history.goBack()} disabled={!!createFxSuccess}>
                {t('general.cancel')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default withRouter(EditCurrency)
