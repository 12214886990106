import { action } from 'typesafe-actions'
import { B2CTransactionTypes } from './types'
import { B2CTransaction, B2CPayout } from '../../interfaces'

export const b2cPaymentRequest = (payload: B2CTransaction) =>
  action(B2CTransactionTypes.B2C_PAYMENT_REQUEST, payload)

export const b2cPaymentSuccess = (response: B2CPayout) =>
  action(B2CTransactionTypes.B2C_PAYMENT_SUCCESS, response)

export const clearStates = () => action(B2CTransactionTypes.CLEAR_STATES)
export const b2cPaymentFailure = (error: any) =>
  action(B2CTransactionTypes.B2C_PAYMENT_FAILURE, error)
