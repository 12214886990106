import React, { Suspense, Fragment, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Spin, Row, Upload, message, Button, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import DataTable from '../components/bulkPayment/DataTable'
import { appSelector } from '../helpers/appSelector'
import Wizard from '../components/bulkPayment/Wizard'
import { PaymentPage } from '../interfaces'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../helpers/appDispatch'
import { addPaymentPageRequest } from '../store/payment-pages'
import { uploadFile } from '../store/bulk-payment'

const { Content } = Layout

const BulkPayment = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const [excel, setExcel] = useState('')
  const [loading] = useState(false)

  const { pages } = appSelector((state) => state.page)
  const [pageData] = useState(pages)

  const beforeUpload = (file: File): boolean => {
    const isExcel =
      file.type === 'text/csv' ||
      file.name.slice(file.name.lastIndexOf('.')) === '.xlsx'

    if (!isExcel) {
      message.error(`${t('general.fileUploadTypeError')}`)
    }
    setExcel(file.name)

    return isExcel
  }

  const uploadButton: React.ReactNode = (
    <div>
      {<PlusOutlined />}
      <div className="ant-upload-text">{t('general.chooseFile')}</div>
    </div>
  )

  let container: React.ReactNode
  if (loading) {
    container = (
      <Row className="suspense-container">
        <Spin style={{ marginTop: '200px' }} />
      </Row>
    )
  }

  const onValidateFile = () => {
    const payload: PaymentPage = {
      Amount: '',
      Description: '',
      EmailAddress: false,
      Logo: '',
      PageName: '',
      PhoneNumber: false,
      RedirectUrl: '',
      Currency: '',
      CustomerName: false,
      ProcessId: '',
      TransactionReference: ''
    }
    dispatch(uploadFile(payload))
  }

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
              }}
            >
              <div style={{ marginTop: '200px' }}>
                <Spin />
              </div>
            </Row>
          }
        >
          <Fragment>
            <Wizard />

            <Upload
              name="Logo"
              listType="picture-card"
              className="seo-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={() => null}
            >
              {excel ? excel : uploadButton}
            </Upload>
            <Button
              type="primary"
              className="resetButton"
              onClick={() => onValidateFile()}
            >
              {t('general.uploadFile')}
            </Button>
            <div className="profit-card">{container}</div>
            {loading ? (
              <div>
                <DataTable
                  pages={pageData}
                  onClickRow={() => null}
                  onPreviewClick={() => null}
                  loading={loading}
                  translate={t}
                />

                <Form.Item className="btn-input">
                  <Button
                    type="primary"
                    className="login-btn"
                    htmlType="submit"
                    loading={loading}
                    icon={loading ? <LoadingOutlined /> : null}
                  >
                    {!loading
                      ? t('general.processPayment')
                      : t('login.Please wait . .')}
                  </Button>
                </Form.Item>
              </div>
            ) : null}
          </Fragment>
        </Suspense>
      </Content>
    </div>
  )
}

export default withRouter(BulkPayment)
