import React, { lazy, Suspense, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Spin, Row, Tabs, Form } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { appSelector } from "../helpers/appSelector";
import { AppDispatch } from "../helpers/appDispatch";

import { useTranslation } from "react-i18next";
import {
  clearSomeBooleans,
  createFxRequest,
} from "../store/fxManage/actions";

const { Content } = Layout;
const { TabPane } = Tabs;

const CreatFX = lazy(() => import("../components/fxCurrencies/CreatFX"));

interface FxEntry {
  SourceCurrency: string;
  DestinationCurrency: string;
  Selling: string;
  Buying: string;
  Median: string;
  UserName: string;
}

export const FxCurrencies = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = appSelector((state) => state.auth);

  const [form] = Form.useForm();
  const initialValues: FxEntry = {
    SourceCurrency: "",
    DestinationCurrency: "",
    Selling: "",
    Buying: "",
    Median: "",
    UserName: "",
  };

  const { createFxSuccess, currencyError, isSubmitting } = appSelector(
    (state) => state.fxCurrencies
  );

  const createFx = (values: FxEntry) => {
    const payload: FxEntry = {
      SourceCurrency: values.SourceCurrency,
      DestinationCurrency: values.DestinationCurrency,
      Buying: values.Buying,
      Selling: values.Selling,
      Median: values.Median,
      UserName: user ? user?.username : "",
    };

    dispatch(createFxRequest(payload));
  };

  useEffect(() => {
    if (createFxSuccess) {
      form.resetFields();
      dispatch(clearSomeBooleans());
    }
  }, [createFxSuccess, form, t, dispatch]);

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row className="suspense-container">
              <div style={{ marginTop: "200px" }}>
                <Spin />
              </div>
            </Row>
          }
        >
          <div className="settings-page">
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span onClick={() => dispatch(clearSomeBooleans())}>
                    <UserAddOutlined /> {t("general.fxEntry")}
                  </span>
                }
                key="2"
              >
                <CreatFX
                  Form={Form}
                  errors={currencyError}
                  form={form}
                  isSubmitting={isSubmitting}
                  onSubmit={createFx}
                  success={createFxSuccess}
                  values={initialValues}
                  translate={t}
                />
              </TabPane>
            </Tabs>
          </div>
        </Suspense>
      </Content>
    </div>
  );
};

export default withRouter(FxCurrencies);
