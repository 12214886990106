import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { CurrenciesTypes } from "./types";

import { callApiGet, callApiPost } from "../../utils/api";
import { Register } from "../../interfaces";
import { path } from "../../helpers/path";
import { logout } from "../auth";
import {
  createFxFailure,
  createFxSuccess,
  getAllCurrenciesFailure,
  getAllCurrenciesSuccess,
} from "./actions";

function* createFx({ payload }: { type: string; payload: Register }): any {
  try {
    const res = yield call(callApiPost, "payments/addfxentry", payload);
    yield put(createFxSuccess(res.data));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        if (err.response.data.status) {
          yield put(createFxFailure(err.response.data));
        } else {
          yield put(createFxFailure("An error occured"));
        }
      }
    } else {
      throw err;
    }
  }
}

function* getAllCurrencies(): any {
  try {
    const res = yield call(callApiGet, "payments/fxcurrencies");

    yield put(getAllCurrenciesSuccess(res.data));
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(getAllCurrenciesFailure(err.response.data));
      }
    } else {
      yield put(getAllCurrenciesFailure("An unknown error occurred"));
    }
  }
}

function* watchCreateMerchant() {
  yield takeEvery(CurrenciesTypes.CREATE_CURRENCY_REQUEST, createFx);
}
function* watchGetAllCurrencies() {
  yield takeEvery(CurrenciesTypes.GET_FX_CURRENCIES, getAllCurrencies);
}

function* currenciesSaga(): Generator {
  yield all([fork(watchCreateMerchant), fork(watchGetAllCurrencies)]);
}

export { currenciesSaga };
