import { Reducer } from 'redux'
import { BulkPaymentState, FileUploadTypes } from './types'
import { AuthActionTypes } from '../auth'

export const initialState: BulkPaymentState = {
  error: undefined,
  failure: false,
  loading: false,
  pages: [],
  success: false,
  isSubmitting: false,
  singlePage: undefined,
  pageTransactions: [],
  fee: undefined,
  feeError: undefined,
  feeLoading: false,
  providers: [],
  step: 0
}

const reducer: Reducer<BulkPaymentState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.DESTROY_STATES:
      return initialState

    // case FileUploadTypes.UPLOAD_FILE:
    //   return {
    //     ...state,
    //     isSubmitting: true,
    //     error: {},
    //     success: false,
    //     failure: false
    //   }
    case FileUploadTypes.UPLOAD_FILE:
      return {
        ...state,
        loading: true
      }
    case FileUploadTypes.VALIDATE_FILE:
      return {
        ...state,
        isSubmitting: true,
        error: {},
        success: false,
        failure: false
      }
    case FileUploadTypes.PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: {},
        pages: [action.payload, ...state.pages],
        success: true,
        failure: false
      }
    case FileUploadTypes.PROCESS_PAYMENT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        success: false,
        failure: true
      }

    default:
      return state
  }
}

export { reducer as bulkPaymentReducer }
