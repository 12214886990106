import React, { lazy, Suspense, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Spin, Row, Form, Button, Tabs } from 'antd'
import { useDispatch } from 'react-redux'
import { appSelector } from '../helpers/appSelector'
import { AppDispatch } from '../helpers/appDispatch'
import { useTranslation } from 'react-i18next'
import { clearSomeBooleans, getAllFXCurrencies } from '../store/fxManage/actions'
import { useHistory } from 'react-router-dom'
import TabPane from 'antd/lib/tabs/TabPane'
import { UserAddOutlined } from '@ant-design/icons'

const { Content } = Layout

const ManageFX = lazy(() => import('../components/fxCurrencies/ManageFX'))

export const ManageFXCurrencies = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const {} = appSelector((state) => state.auth)
  const history = useHistory()

  useEffect(() => {
    dispatch(getAllFXCurrencies())
  }, [dispatch])

  const [form] = Form.useForm()

  const { allCurrencies, createFxSuccess, currencyError } = appSelector((state) => state.fxCurrencies)

  useEffect(() => {
    if (createFxSuccess) {
      form.resetFields()
      dispatch(clearSomeBooleans())
    }
  }, [createFxSuccess, form, t, dispatch])

  const addCurrency = (): void => {
    history.push('/fxcurrencies')
  }

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row className="suspense-container">
              <div style={{ marginTop: '200px' }}>
                <Spin />
              </div>
            </Row>
          }
        >
          <div className="settings-page">
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom:'20px'}}>
              <Button
                type="primary"
                className="reset"
                htmlType="submit"
                size="large"
                onClick={() => addCurrency()}
              >
                {t("general.createFx")}
              </Button>
            </div>
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span onClick={() => dispatch(clearSomeBooleans())}>
                    <UserAddOutlined /> {t('general.fxManage')}
                  </span>
                }
                key='2'
              >
                <ManageFX
                  translate={t}
                  fxCurrencies={allCurrencies}
                  errors={currencyError}
                  success={createFxSuccess}
                    />
              </TabPane>
            </Tabs>
          </div>
        </Suspense>
      </Content>
    </div>
  )
}

export default withRouter(ManageFXCurrencies)