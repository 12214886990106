export const path = {
  home: '/',
  dashboard: '/dashboard',
  tranasctions: '/transactions',
  bulkPayment: '/bulkPayment',
  paymentPages: '/payment-pages',
  page: '/page',
  login: '/auth/login',
  pay: '/pay',
  example: '/example-pay',
  payment: '/payment',
  myPayouts: '/my-payouts',
  merchantsOverview: '/merchants-overview',
  vasProcessed: '/vas-processed',
  merchantPayouts: '/merchants-payouts',
  settings: '/settings',
  feeReports: '/fee-reports',
  proxyPaySubscribers: '/proxypay-subscribers',
  proxyPayTransactions: '/proxypay-transactions',
  proxyPayVolumes: '/proxypay-volumes',
  proxyPayOverview: '/proxypay-overview',
  payouts: '/payouts',
  pendingTransactions: '/pending-transactions',
  merchantsChannels: '/merchants-channels',
  forgottenPasssword: '/auth/forgotten-password',
  resetPassword: '/auth/reset-password',
  b2ctransactions: '/b2ctransactions',
  fxCurrencies: '/fxcurrencies',
  fxCurrenciesManage: '/fxCurrencies-manage',
  editCurrency: '/editCurrency',
}
